let shopPlus = document.querySelectorAll('.shop__anzahl-knopf--plus');
let shopMinus = document.querySelectorAll('.shop__anzahl-knopf--minus');
let shopAnzahl = document.querySelectorAll('.shop__anzahl-anzahl');
let shopBestellung = document.querySelector('textarea[name="fields[bestellung]"]');
let shopProdukte = document.querySelector('.shop__produkte');

var packery = new Packery(shopProdukte, {
    itemSelector: '.shop__produkt',
    columnWidth: '.shop__produkt-breite',
    gutter: 0
});


for (i = 0; i < shopPlus.length; i++) {
    shopPlus[i].addEventListener('click', function () {
        let anzahlInput = this.parentElement.querySelector('.shop__anzahl-anzahl');
        let anzahl = parseInt(anzahlInput.value) + 1;
        anzahlInput.value = anzahl;
        bestellungAktualisieren();
    });
}

for (i = 0; i < shopMinus.length; i++) {
    shopMinus[i].addEventListener('click', function () {
        let anzahlInput = this.parentElement.querySelector('.shop__anzahl-anzahl');
        let anzahl = parseInt(anzahlInput.value) - 1;
        anzahlInput.value = anzahl >= 1 ? anzahl : 0;
        bestellungAktualisieren();
    });
}

for (i = 0; i < shopAnzahl.length; i++) {
    shopAnzahl[i].addEventListener('input', function () {
        bestellungAktualisieren();
    });
}

function bestellungAktualisieren() {
    shopBestellung.value = '';
    let summe = 0;
    let bestellungArray = [];
    for (i = 0; i < shopAnzahl.length; i++) {
        if (parseInt(shopAnzahl[i].value) != 0) {
            bestellungArray.push(
                {
                    'titel': shopAnzahl[i].getAttribute('data-titel'),
                    'kategorie': shopAnzahl[i].getAttribute('data-kategorie'),
                    'preis': shopAnzahl[i].getAttribute('data-preis'),
                    'anzahl': shopAnzahl[i].value
                }
            );
        }
    }
    for (i = 0; i < bestellungArray.length; i++) {
        let preis = parseFloat(bestellungArray[i].preis);
        let anzahl = parseInt(bestellungArray[i].anzahl);
        shopBestellung.value += `${anzahl} × ${bestellungArray[i].titel} (${bestellungArray[i].kategorie}) zu je CHF ${preis.toFixed(2)} \n`;
        summe += preis * anzahl;
    }
    if (summe > 0) {
        shopBestellung.value += (' \nSumme: CHF ' + summe.toFixed(2));
    } else {
        shopBestellung.value = '';
    }

    shopBestellung.parentNode.dataset.replicatedValue = shopBestellung.value;
}


let $shopForm = document.querySelector('.shop');
let shopBestaetigung = document.getElementById('kontaktformular__bestaetigung');
let shopSchliessen = document.getElementById('kontaktformular__bestaetigung-schliessen');

$shopForm.addEventListener('onAfterFormieSubmit', (e) => {
    e.preventDefault();
    let data = e.detail;
    shopBestaetigung.setAttribute('data-bestaetigt', true);

    shopSchliessen.addEventListener('click', function() {
        shopBestaetigung.setAttribute('data-bestaetigt', false);
    })
});
